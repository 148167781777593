<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-template"></i>{{ $t("Template List") }}
    </h2>
    <section class="searchArea w50 align-end">
      <div class="form-group form-inline" :style="{ width: '37%' }">
        <v-text-field
          v-model="search.templateTitle"
          class="form-input"
          :placeholder="$t('Input template title')"
          @keyup.enter="clickSearch"
          :label="$t('Template Title')"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>
      <div class="form-group form-inline ml-3" :style="{ width: '37%' }">
        <v-text-field
          v-model="search.templateId"
          class="form-input"
          :placeholder="$t('Input template ID')"
          @keyup.enter="clickSearch"
          :label="$t('Template ID')"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </div>
      <v-btn
        :style="{ width: '164px' }"
        @click="clickSearch()"
        class="btn type-search ml-3"
        text
        >{{ $t("Search") }}
      </v-btn>
    </section>

    <perfect-scrollbar style="width: 1323px; height: 539px" class="mt-7">
      <v-item-group v-model="selected" multiple class="template-list">
        <v-row no-gutters>
          <v-col v-for="(item, index) in templateList" :key="index" class="col-width">
            <v-item v-slot="{ active, toggle }">
              <v-card :color="active ? 'primary' : ''" @click="toggle">
                <v-card-title>
                  <v-checkbox :input-value="active" hide-details></v-checkbox>
                  <span>{{ item.idx_template }}</span><span>{{ item.title }}</span>
                  <!-- item.idx_template // 인덱스 -->
                </v-card-title>
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="template-image" style="width: 150px; height: 150px;">
                      <img :src="getThumbnail(item.template_img)" style="max-height: 150px; max-width: 150px"/>
                    </div>
                    <div class="template-summary">
                      <!-- <p>Size(mm) : 120×60</p> -->
                      <p>{{$t('Pixel')}} : {{ item.width_px }} × {{ item.height_px }}</p>
                      <p>{{$t('Animation Time')}} : {{ item.raw_data ? getAnimationTime(item.raw_data) : '' }}</p>
                      <p>{{$t('Layer')}} : {{ item.raw_data ? getLayer(item.raw_data) : '' }}</p>
                      <p>{{$t('Trigger')}} : {{ item.raw_data ? getTriggerData(item.raw_data) : '' }}</p>
                      <p>{{$t('Last Modified Date')}} : {{ item.raw_data ? getDate(item) : '' }}</p>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </perfect-scrollbar>
    <!-- button group -->
    <div class="btn-wrap mt-7">
      <div class="mb-2">
        <v-btn @click="newTemplate()" :disabled="btnDisabledNewEditDelete" text class="btn">{{ $t("New") }} </v-btn>
        <v-btn @click="editTemplate()" text class="btn ml-3" :disabled="editBtnDisabled || btnDisabledNewEditDelete ">{{ $t("Edit") }} </v-btn>
        <v-btn text class="btn ml-3" :disabled="deleteBtnDisabled || btnDisabledNewEditDelete" @click="deleteDialog = true">{{ $t("Delete") }} </v-btn>
      </div>
      <div>
        <v-btn text class="btn"  :disabled="importBtnDsiabled || btnDisabledNewEditDelete " @click="$refs.files.$refs.input.click()">{{ $t("Import") }} </v-btn>
        <v-btn text class="btn ml-3" :disabled="exportBtnDisabled || btnDisabledNewEditDelete " @click="exportTemplate">{{ $t("Export") }} </v-btn>
        <preview-set
          @fireResetTemplate="resetTemplate"
          :templateIndex="selectedTemplateIndex"
          :btnDisabledPreviewSet="editBtnDisabled"
        />
        <!-- <v-btn
          @click="previewSetDialog = true"
          text
          class="btn ml-3"
          >{{ $t('Preview Set') }}
        </v-btn> -->
      </div>
      <div style="float: right;margin-top: -54px;">
        <div class="pageInfo2">{{ pageInfoText }}</div>
        <div class="pagination">
          <!-- 페이지 앞 뒤 이동 -->
          <button
            @click="goTenPageBackwards(page, paging)"
            :class="page < 10 ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-prev"
            type="button"
          >
            <img
              src="@/assets//img/ico-paging-prev.png"
              alt="이전 10페이지 이동"
            />
          </button>
          <v-pagination
            v-model="page"
            @input="paging"
            :length="totalPages * 1 || 1"
            :total-visible="10"
            color="#2f3b4c"
            style="display: block;"
          ></v-pagination>
          <button
            @click="goTenPageForward(page, paging, totalPages)"
            :class="page + 10 > totalPages ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-next"
            type="button"
          >
            <img
              src="@/assets//img/ico-paging-next.png"
              alt="다음 10페이지 이동"
            />
          </button>

          <div class="goto-Page d-flex align-center">
            <label>{{$t('Go to')}}</label>
            <v-text-field
              v-model="goToPageInput"
              class="form-input noneline"
              placeholder="Page"
              outlined
              dense
              hide-details
              solo
              @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
            ></v-text-field>

            <!-- <button
              @click="goToPage(goToPageInput, paging, totalPages)"
              type="button"
              class="btnGoToPage"
            >
              <i
                class="v-icon notranslate mdi mdi-chevron-right theme--light"
                aria-hidden="true"
              >
              </i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" width="370">
      <v-card class="popup-success">
        <!-- <i class="ico-error"> <img src="@/assets/img/ico-unassign.png" alt=""> </i> -->
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
            <h3>{{ $t('Delete') }}</h3>
            <p>{{ $t('Are you sure you want to delete?') }}</p>
        </div>
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon class="btn" @click="deleteTemplate">{{$t('Yes')}}</v-btn>
          <v-btn text icon @click="deleteDialog = false" class="btn">{{$t('No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-file-input @change="importTemplate" v-model="chooseFile" ref="files" style="display:none;" accept=".txt"/>
    <v-dialog v-model="isLoading" persistent>
      <div style="width: 100%; overflow: hidden; text-align: center">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="7"
          color="#ddd"
        ></v-progress-circular>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import dicamoApi from '@/lcd/plugins/dicamoApi'
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { mapGetters } from 'vuex'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import PreviewSet from './modal/PreviewSet.vue'
// import configs from '@/plugins/configs'
// import XLSX from 'xlsx'

export default {
  name: 'DicamoTemplate',
  components: {
    PerfectScrollbar,
    PreviewSet
  },
  data () {
    return {
      search: {
        templateTitle: '',
        templateId: ''
      },
      chooseFile: null,
      deleteDialog: false,
      srcURL: dicamoApi.requests.labelThumbnail.url,
      page: 1,
      selected: [],
      templateList: [],
      newDialog: false,
      editDialog: false,
      previewSetDialog: false,
      viewTemplateList: true,
      btnDisabledNewEditDelete: true,
      btnDisabledDetail: true,
      isLoading: false,
      goToPageInput: 0
    }
  },
  created () {
    this.user = this.$store.state.auth.user
  },
  mounted () {
    // LCD PlaylistGroup Mapping / Assigng
    this.$store.dispatch('auth/getDisabledBtn', '25100').then(flag => {
      this.viewTemplateList = flag
    })
    // LCD Registration
    this.$store.dispatch('auth/getDisabledBtn', '25001').then(flag => {
      this.btnDisabledNewEditDelete = flag
    })
    // LCD Edit / Delete
    this.$store.dispatch('auth/getDisabledBtn', '25002').then(flag => {
      this.btnDisabledDetail = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    if (!this.store.code) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.getTemplateList()
    }
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    paging (page) {
      this.page = Number(page)
      this.getTemplateList()
    },
    exportTemplate () {
      const idxTemplates = []
      this.templateList.forEach(item => {
        idxTemplates.push(item.idx_template)
      })

      const data = new FormData()

      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_templates', JSON.stringify(idxTemplates))
      data.append('type', 'export')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateExport.method,
          dicamoApi.requests.getTemplateExport.url,
          data
        )
        .then(res => {
          const exportList = res.data.data

          const blob = new Blob([JSON.stringify(exportList)], { type: 'text/plain' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = 'template.txt'
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.debug(`Post error template item. error:, ${error}`)
        })

      // const excel = XLSX.utils.json_to_sheet(json)

      // var wb = XLSX.utils.book_new()

      // XLSX.utils.book_append_sheet(wb, excel, 'template')

      // XLSX.writeFile(wb, 'Template.csv')
    },
    importTemplate () {
      if (!this.chooseFile) return

      const reader = new FileReader()

      reader.readAsText(this.chooseFile)
      reader.onload = () => {
        const data = new FormData()

        data.append('company', this.$store.state.auth.user.company)
        data.append('store', this.$store.state.dataStore.selectedStore.code)
        data.append('file', this.chooseFile)
        // data.append('data', reader.result)
        data.append('type', 'import')

        this.$utils
          .callAxiosWithBody(
            dicamoApi.requests.setTemplateImport.method,
            dicamoApi.requests.setTemplateImport.url,
            data
          )
          .then(res => {
            this.chooseFile = null
            this.resetTemplate()
            if (res.data.result) {
              EventBus.$emit('messageAlert', this.$t('SUCCESS'))
            } else {
              EventBus.$emit('messageAlert', this.$t(res.data.message))
            }
          })
          .catch(error => {
            console.debug(`Post error template item. error:, ${error}`)
          })
      }
    },
    getAnimationTime (item) {
      const data = JSON.parse(item)
      if (item === null) return ''

      const animationTime = data.animation.actionTime.selected.value
      if (animationTime === 3000) return 'Very Slow'
      else if (animationTime === 1500) return 'Slow'
      else if (animationTime === 600) return 'Normal'
      else if (animationTime === 300) return 'Fast'
      else if (animationTime === 100) return 'Very Fast'
      else return 'Custom Time : ' + animationTime
    },
    getTriggerData (item) {
      const data = JSON.parse(item)
      if (item === null) return ''

      return data.animation.trigger.selected
    },
    getLayer (item) {
      const data = JSON.parse(item)
      if (item === null) return ''

      return data.info.layer.value
      // return data.info.layer.value
    },
    getDate (item) {
      // if (configs.Tz_Enable) {
      const date = new Date(item.m_time)
      return commons.convertDate(date)
      // } else {
      //   return item.m_time.substring(0, item.m_time.indexOf('.'))
      // }
    },
    newTemplate () {
      this.$router.push('/Lcd/Template/NewTemplateList')
    },
    editTemplate () {
      // this.$router.push('/Lcd/Template/EditTemplateList')
      // EventBus.$emit('editTemplateIndex', 'index')=
      const index = Number(this.templateList[this.selected[0]].idx_template)
      window.sessionStorage.setItem('template_index', index)
      this.$router.push({ name: 'Dicamo Template Edit', params: { index: index } })
    },
    clickSearch () {
      this.page = 1
      this.getTemplateList()
    },
    resetTemplate () {
      // document.getElementsByClassName('ps--active-y')[0].scrollTop = 0
      // this.getTemplateList()
      this.page = 1
      // this.selected = []
      this.templateList = []
      this.selected = []
      this.getTemplateList()
    },
    deleteTemplate () {
      const data = new FormData()
      const templateIndexs = []
      this.selected.forEach(item => {
        templateIndexs.push(this.templateList[item].idx_template)
      })
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('idx_templates', JSON.stringify(templateIndexs))
      data.append('timezone', this.$store.state.dataStore.timezone)
      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.deleteTemplateList.method,
          dicamoApi.requests.deleteTemplateList.url,
          data
        )
        .then(res => {
          const data = res.data
          this.deleteDialog = false
          EventBus.$emit('messageAlert', data.message === '' ? this.$t('SUCCESS') : this.$t(data.message))
          this.resetTemplate()
          // this.lcdlist = res.data.data
        })
        .catch(error => {
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    getTemplateList () {
      const data = new FormData()
      this.isLoading = true
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('page', this.page)
      data.append('limit', 6)
      data.append('type', 'list')
      data.append('timezone', this.$store.state.dataStore.timezone)
      if (!commons.isNull(this.search.templateTitle))data.append('searchWord', this.search.templateTitle ? this.search.templateTitle : '')
      if (!commons.isNull(this.search.templateId))data.append('idx_template', this.search.templateId ? this.search.templateId : '')

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          data
        )
        .then(res => {
          // console.log(res.data.data)
          this.templateList = res.data.data
          this.isLoading = false
          // this.lcdlist = res.data.data
        })
        .catch(error => {
          this.lcdlist = []
          console.debug(`Could not find any template. error: ${error}`)
          this.isLoading = false
        })
    },
    getThumbnail (filename) {
      const thumb = filename.split('.')
      return this.srcURL + thumb[0] + '_thumb.' + thumb[1] + '?t=' + new Date().getTime()
    }
  },
  computed: {
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    addBtnDisabled () {
      return this.btnDisabledNewEditDelete || !this.store.code
    },
    editBtnDisabled () {
      return this.btnDisabledNewEditDelete || this.selected.length !== 1
    },
    deleteBtnDisabled () {
      return this.btnDisabledNewEditDelete || this.selected.length < 1
    },
    selectedTemplateIndex () {
      if (this.selected.length === 1) return this.templateList[this.selected].idx_template
      else return ''
    },
    importBtnDsiabled () {
      const btnDisabled = this.btnDisabledNewEditDelete || !this.store.code
      return btnDisabled
    },
    exportBtnDisabled () {
      let btnDisabled = this.btnDisabledNewEditDelete
      if (!btnDisabled) {
        btnDisabled = this.templateList !== undefined && this.templateList.length < 1
      }
      return btnDisabled
    },
    totalRecords () {
      let count = 0
      if (!commons.isNull(this.templateList) && this.templateList.length > 0) count = this.templateList[0].total
      return Number(count)
    },
    totalPages () {
      // return Math.round(this.totalRecords / 5)
      return Math.ceil(this.totalRecords / 6)
    },
    pageInfoText () {
      // return ''
      const curPage = (this.page - 1) * 1 * 6 + 1
      const curPageTo = (this.page - 1) * 6 + this.templateList.length * 1
      // if (this.totalPages !== this.page) curPageTo++
      return curPage + this.$t(' to ') + curPageTo + ', ' + this.totalRecords + this.$t('in total')
      // return '0 to 0, 0'
    }
  },
  watch: {
    store: {
      handler () {
        this.selected = []
        this.getTemplateList()
      }
    },
    page: {
      handler () {
        this.selected = []
        this.getTemplateList()
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-input input:focus::placeholder {
  color: #fff;
}
.v-btn.type-search .v-btn__content {
  font-size: var(--font22);
  font-weight: 700;
}
.pageInfo2 {
    position: relative;
    right: 0;
    font-size: var(--font16);
    color: #808495;
    line-height: 1;
    text-align: right;
    bottom: 5px;
}
</style>
