<template>
  <v-dialog v-model="previewSetDialog" width="700" class="rounded-0" scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="btnDisabledPreviewSet"
        class="btn ml-3"
        v-bind="attrs"
        v-on="on"
        text
      >{{ $t('Preview Set') }}</v-btn>
    </template>
    <v-card class="popup add_store_popup" style="width: 100%;">
      <v-card-title><h3 class="page-title-bar"><i class="ico ico-template"></i>{{$t('Preview Set')}}</h3></v-card-title>
      <v-card-text class="pa-0">
        <div class="form-group mb-4">
          <!-- <label>Product</label> -->
          <div class="d-flex">
            <v-select
              v-model="searchType"
              :style="{ 'font-size': 'var(--font13)', flex: 1 }"
              class="form-combobox"
              :items="['Product ID', 'Product Name']"
              hide-details
              dense
              solo
              outlined
            />
            <v-text-field
              v-model="searchProduct"
              :style="{ flex: 2 }"
              :items="productList"
              :placeholder="$t('Product Id or Name')"
              class="form-combobox form-combobox-search"
              prepend-inner-icon="mdi-magnify"
              @click:prepend-inner="getProductList(0)"
              @keyup.enter="getProductList(0)"
              hide-details
              dense
              outlined
            ></v-text-field>
          </div>
        </div>
        <div class="tbl-type05">
          <v-data-table
            v-model="selected"
            show-select
            single-select
            height="270"
            item-key="articleId"
            :headers="headers"
            :items="productList"
            hide-default-footer
          >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.articleId="{ item }">
            <div style="max-width: 110px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"> {{ item.articleId }} </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.articleName="{ item }">
            <div style="max-width: 110px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"> {{ item.articleName }} </div>
          </template>
          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
          </v-data-table>

            <!-- <v-simple-table>
              <template v-slot:default>
                <colgroup>
                  <col width="10%">
                  <col width="35%">
                  <col>
                </colgroup>
                <thead>
                  <tr>
                    <th class="text-left"><v-checkbox value hide-details></v-checkbox></th>
                    <th class="text-left">Product ID</th>
                    <th class="text-left">Name</th>
                    </tr>
                </thead>
            </template>
          </v-simple-table>
          <perfect-scrollbar style="height:228px;">
            <v-simple-table>
              <template v-slot:default>
                <colgroup>
                  <col width="10%">
                  <col width="35%">
                  <col>
                </colgroup>
                <tbody>
                  <tr>
                    <td class="text-left"><v-checkbox value hide-details></v-checkbox></td>
                    <td class="text-left">8888888888</td>
                    <td class="text-left">product namename</td>
                  </tr>
                  <tr>
                </tbody>
              </template>
            </v-simple-table>
          </perfect-scrollbar> -->
        </div>
      </v-card-text>
      <!-- <v-card-actions> -->
        <div style="width: 100%;margin-top: 55px;margin-left: 50px;">
          <div class="pageInfo">{{ pageInfoText }}</div>
            <div class="pagination2">
              <!-- 페이지 앞 뒤 이동 -->
              <!-- <button
                @click="goTenPageBackwards(page, paging)"
                :class="page < 10 ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-prev"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-prev.png"
                  alt="이전 10페이지 이동"
                />
              </button> -->
              <v-pagination
                v-model="page"
                @input="paging"
                :length="totalPages * 1 || 1"
                :total-visible="10"
                color="#2f3b4c"
                style="display: block;"
              ></v-pagination>
              <!-- <button
                @click="goTenPageForward(page, paging, totalPages)"
                :class="page + 10 > totalPages ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-next"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-next.png"
                  alt="다음 10페이지 이동"
                />
              </button> -->
            </div>
          </div>
      <!-- </v-card-actions> -->
      <v-card-actions class="d-flex justify-content-center" style="justify-content: center;">
        <v-btn text icon @click="previewSet" class="btn" :style="{ width: '112px' }">{{$t('Save')}}</v-btn>
        <v-btn text icon @click="cancelPreviewset" class="btn" :style="{ width: '112px' }">{{$t('Cancel')}}</v-btn>
      </v-card-actions>
      <!-- <v-dialog v-model="previewDialog" width="1500" class="rounded-0" @click:outside="previewDialog = false"> -->
        <div style="overflow: hidden; width: 1px; height: 1px;">
        <!-- <div> -->
          <div v-if="previewDialog" class="d-flex" id="previewCanvas" :style="{ 'justify-content': 'center', width: canvas.width + 'px', height: canvas.height + 'px' }" ref="refPreview">
            <div style="position: relative">
              <div v-for="(item, index) in templateData.bind" :key="index"
                >
                <label :style="getStyle(item)"> {{ item.column !== 'BARCODE' ? getArticle(item.column) : '' }} </label>
                <barcode class="barcode" :options="{ displayValue: false }" v-if="item.column === 'BARCODE' && false" v-bind:value="getArticle(item.column)" tag="img" />
              </div>
              <img :src="srcURL + templateItem.template_img"/>
              <!-- <div :style="{ 'background-image' : 'url(' + srcURL + templateItem.template_img +')', width: canvas.width + 'px', height: canvas.height + 'px' }" ></div> -->
            </div>
          </div>
        </div>
      <!-- </v-dialog> -->
      <div style=" width: 1px; height: 1px; overflow: hidden;">
        <canvas v-if="previewDialog" id="canvas" :width="canvas.width" :height="canvas.height"></canvas>
      </div>
    </v-card>
    <v-dialog v-model="isLoading" persistent>
      <v-progress-circular
        style="width:100%; overflow: hidden;"
        indeterminate
        :size="50"
        :width="7"
        color="#ddd"
      ></v-progress-circular>
    </v-dialog>
  </v-dialog>
</template>

<script>
/* eslint-disable */
// import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import dicamoApi from '@/lcd/plugins/dicamoApi'
// import VueBarcode from 'vue-barcode'
import VueBarcode from '@xkeshi/vue-barcode'
export default {
  // components: {
  //   draggable
  // },
  components: {
    barcode: VueBarcode
    // PerfectScrollbar
  },
  props: {
    btnDisabledPreviewSet: Boolean,
    templateIndex: String
    // user: Object,
  },
  data () {
    return {
      canvas: {
        width: 0,
        height: 0
      },
      searchType: 'Product ID',
      isLoading: false,
      previewDialog: false,
      srcURL: dicamoApi.requests.labelThumbnail.url,
      previewSetDialog: false,
      searchProduct: '',
      selected: [],
      selectedArticle: [],
      productList: [],
      templateItem: [],
      templateData: [],
      headers: [
        { text: this.$t('Product ID'), align: 'start', sortable: false, value: 'articleId', width: '40%' },
        { text: this.$t('Product Name'), align: 'start', sortable: false, value: 'articleName', width: '40%' }
      ],
      totalRecords: 0,
      totalPages: 0,
      page: 1,
      base64: '',
      pageInfoText:''
    }
  },
  mounted () {
    this.getProductList(0)
    this.getTemplateData()
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    paging (page) {
      this.page = Number(page)
      this.getProductList(page)
    },
    cancelPreviewset () {
      this.$emit('fireResetTemplate')
      this.previewSetDialog = false
      this.searchType = 'Product ID'
      this.previewDialog = false
      this.selected = []
      this.productList = []
      this.searchProduct = ''
      this.templateData = []
      this.templateitem = []
      this.selectArticle = []
    },
    getTemplateData () {
      if (this.templateIndex === '') return
      const data = new FormData()
      data.append('company', this.$store.state.auth.user.company)
      data.append('store', this.$store.state.dataStore.selectedStore.code)
      data.append('type', 'item')
      data.append('idx_template', this.templateIndex)
      data.append('timezone', this.$store.state.dataStore.timezone)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.getTemplateList.method,
          dicamoApi.requests.getTemplateList.url,
          data
        )
        .then(res => {
          this.templateItem = res.data.data
          this.templateData = JSON.parse(this.templateItem.template_data)

          this.canvas.width = this.templateData.width
          this.canvas.height = this.templateData.height
          // this.templateList = res.data.data
          // this.lcdlist = res.data.data
        })
        .catch(error => {
          this.lcdlist = []
          console.debug(`Could not find any template. error: ${error}`)
        })
    },
    getProductList (page) {
      let currentPage = 0
      if (page > 0) {
        this.page = page
        currentPage = page - 1
      } else if (page === 0) {
        this.page = 1
      }
      if (this.templateIndex === '') return
      const config = {
        params: {
          store: this.$store.state.dataStore.selectedStore.code,
          company: this.$store.state.auth.user.company,
          size: 6,
          page: currentPage
        }
      }
      if (this.searchProduct) {
        if (this.searchType === 'Product ID') config.params.id = this.searchProduct
        else if (this.searchType === 'Product Name') config.params.name = this.searchProduct
      }
      this.$utils
        .callAxios(
          codes.requests.getArticles.method,
          codes.requests.getArticles.url,
          config
        )
        .then(res => {
          // this.saveRequestConfig(res.config)
          // this.products = this.convertProductsDate(res.data.articleList)
          // this.totalProducts = this.products.length
          // this.pageInfoText = commons.getPageInfoText(res.headers)
          // this.totalPages = Number(res.headers['x-totalpages'])
          // this.selected = []
          this.totalRecords = Number(res.headers['x-totalelements'])
          this.totalPages =  Math.ceil(this.totalRecords / 6)
          this.productList = res.data.articleList
        })
        .catch(error => {
          console.debug(`Could not find any products. error: ${error}`)
        })
    },
    getProductDetail (articleId) {
      if (commons.isNull(articleId)) return
      const params = {
        store: this.$store.state.dataStore.selectedStore.code,
        article: articleId
      }
      const config = { params }
      params.company = this.$store.state.auth.user.company
      this.$utils
        .callAxios(
          codes.requests.getAnArticle.method,
          codes.requests.getAnArticle.url,
          config
        )
        .then(res => {
          this.selectedArticle = res.data

          this.canvasToImage()
        })
        .catch(error => {
          console.debug(`Failed to get data of an article. ${error}`)
        })
    },
    getStyle (item) {
      this.$nextTick(() => {
        const style = {
          position: 'absolute',
          top: item.y + 'px',
          left: item.x + 'px',
          'text-align': item.align,
          color: item.fill,
          'font-family': item.fontFamily,
          'font-size': item.fontSize + 'px',
          'font-weight': item.fontStyle,
          'font-style': item.fontStyle,
          '-webkit-text-stroke-width': item.strokeWidth + 'px',
          '-webkit-text-stroke-color': item.stroke,
        }
        return style
      })
    },
    getArticle (articleId) {
      const article = this.selectedArticle.data
      if (article) {
        for (const [key, value] of Object.entries(article)) {
          if (key === articleId) {
            return value
          }
        }
      }
      // return
    },
    previewSet () {
      this.isLoading = true
      this.previewDialog = true
      console.log(this.selected.length)
      if (this.selected.length === 0) {
        this.isLoading = false
        return
      }
      this.getProductDetail(this.selected[0].articleId)
    },
    async canvasToImage () {
      let canvas = document.getElementById('canvas')
      let ctx = canvas.getContext('2d')
      let text = ''

      try {
        const img = new Image()
        img.addEventListener('load', () => {
          ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)

          product.forEach((item, index) => {
            if (item.column !== 'barcode') {
              text = this.getArticle(item.column)
              if (!text) return
              // ctx = canvas.getContext('2d')
              let maxLine = item.line === 0 ? 999 : item.line
              if (!maxLine) maxLine = 999
              // const align = item.align === 'left' ? 'start' : item.align === 'right' ? 'end' : 'center'
              ctx.font = `${item.fontSize}px ${item.fontFamily}`
              ctx.strokeStyle = item.stroke
              if (item.y < item.fontSize) {
                ctx.textBaseline = 'bottom'
              } else {
                ctx.textBaseline = 'top'
              }
              ctx.lineWidth = item.strokeWidth
              ctx.width = item.width
              ctx.height = item.height
              ctx.textAlign = item.align
              ctx.fillStyle = item.fill
              let offset = 0
              if (item.align === 'left') offset = 0
              else if (item.align === 'center') offset = item.width / 2
              else if (item.align === 'right') offset = item.width
              if (!item.wrap) item.wrap = 'word'

              // console.log(`align : ${item.align} offset: ${offset} x: ${item.x}`)

              if (!item.fit) {
                let line = ''
                let lineHeight = 0
                let count = 0
                let testLine = ''
                let metrics = ''
                let testWidth = 0
                const maxWidth = item.width
                if (item.wrap === 'word') {
                  const words = String(text).split(' ')

                  for (let n = 0; n < words.length; n++) {
                    testLine = line + words[n]
                    metrics = ctx.measureText(testLine)
                    testWidth = metrics.width
                    if (testWidth > maxWidth && n > 0 && item.height > lineHeight) {
                      if (maxLine > count) {
                        if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight)
                        ctx.fillText(line, item.x + offset, item.y + lineHeight)
                      }
                      line = words[n] + ' '
                      lineHeight += item.fontSize
                      count++
                    } else {
                      line = testLine + ' '
                    }
                  }
                  if (line.length > 0 && maxLine > count) {
                    if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight, item.width)
                    ctx.fillText(line, item.x + offset, item.y + lineHeight, item.width)
                  }
                } else {
                  // const words = String(text)
                  for (let i = 0; i < text.length; i++) {
                    testLine = line + text[i]
                    metrics = ctx.measureText(testLine)
                    testWidth = metrics.width
                    if (testWidth > maxWidth && i > 0 && item.height > lineHeight) {
                      if (maxLine > count) {
                        if (item.strokeWidth > 0) ctx.strokeText(line, item.x + offset, item.y + lineHeight)
                        ctx.fillText(line, item.x + offset, item.y + lineHeight)
                      }
                      line = text[i] + ''
                      lineHeight += item.fontSize
                      count++
                    } else {
                      line = testLine + ''
                    }
                  }
                  if (line.length > 0 && maxLine > count) {
                    if (item.strokeWidth > 0) ctx.strokeText(testLine, item.x + offset, item.y + lineHeight)
                    ctx.fillText(testLine, item.x + offset, item.y + lineHeight)
                  }
                }
              } else {
                if (item.strokeWidth > 0) ctx.strokeText(text, item.x, item.y + item.fontSize, item.width)
                ctx.fillText(text, item.x, item.y + item.fontSize, item.width)
              }
            }
            this.base64 = canvas.toDataURL()
          })
          this.isLoading = false
          // return
          const data = new FormData()
          data.append('company', this.$store.state.auth.user.company)
          data.append('store', this.$store.state.dataStore.selectedStore.code)
          data.append('idx_template', this.templateIndex)
          data.append('thumbnail_img', this.base64)
          data.append('type', 'thumbnail')
          this.$utils
            .callAxiosWithBody(
              dicamoApi.requests.setTemplatePreview.method,
              dicamoApi.requests.setTemplatePreview.url,
              data
            )
            .then(res => {
              this.isLoading = false
              this.cancelPreviewset()
              // this.templateList = res.data.data
              // this.lcdlist = res.data.data
            })
            .catch(error => {
              this.isLoading = false
              console.debug(`Could not find any preview set. error: ${error}`)
            })
        }, false)
        img.src = this.srcURL + this.templateItem.template_img
        img.crossOrigin = 'Anonymous'

        const product = this.templateData.bind

      } catch (ex) {
        this.isLoading = false
        console.error(ex)
      }
      // const style = {
      //   position: 'absolute',
      //   top: item.y + 'px',
      //   left: item.x + 'px',
      //   'text-align': item.align,
      //   color: item.fill,
      //   'font-family': item.fontFamily,
      //   'font-size': item.fontSize + 'px',
      //   'font-weight': item.fontStyle,
      //   'font-style': item.fontStyle,
      //   '-webkit-text-stroke-width': item.strokeWidth + 'px',
      //   '-webkit-text-stroke-color': item.stroke


              // <div v-for="(item, index) in templateData.bind" :key="index"
              //   >
              //   <label :style="getStyle(item)"> {{ item.column !== 'BARCODE' ? getArticle(item.column) : '' }} </label>
              //   <barcode class="barcode" :options="{ displayValue: false }" v-if="item.column === 'BARCODE' && false" v-bind:value="getArticle(item.column)" tag="img" />
              // </div>
      // this.base64 = await this.$html2canvas(el, options)
    }
  },
  watch: {
    previewSetDialog (isDialog) {
      if (isDialog) {
        this.getProductList(0)
        this.getTemplateData()
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped>
::v-deep .v-dialog {
  max-width: 40%;
}
::v-deep .text-start {
  padding: 0px !important;
}
::v-deep .v-simple-checkbox {
  text-align: center !important;
}
::v-deep .v-data-table__selected {
  background: #031E37 !important;
  color: white;
}
/* ::v-deep .mdi-checkbox-marked {
  color: white !important;
} */
::v-deep .mdi-checkbox-blank-outline {
  color: #E2E2E2 !important;
}
::v-deep .mdi-checkbox-marked {
  background-color: #FFFFFF !important;
}
::v-deep .barcode {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 180px !important;
  height: 20px !important;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
