var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-0",
      attrs: { width: "700", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn ml-3",
                      attrs: { disabled: _vm.btnDisabledPreviewSet, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Preview Set")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.previewSetDialog,
        callback: function($$v) {
          _vm.previewSetDialog = $$v
        },
        expression: "previewSetDialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "popup add_store_popup",
          staticStyle: { width: "100%" }
        },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-template" }),
              _vm._v(_vm._s(_vm.$t("Preview Set")))
            ])
          ]),
          _c("v-card-text", { staticClass: "pa-0" }, [
            _c("div", { staticClass: "form-group mb-4" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("v-select", {
                    staticClass: "form-combobox",
                    style: { "font-size": "var(--font13)", flex: 1 },
                    attrs: {
                      items: ["Product ID", "Product Name"],
                      "hide-details": "",
                      dense: "",
                      solo: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.searchType,
                      callback: function($$v) {
                        _vm.searchType = $$v
                      },
                      expression: "searchType"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "form-combobox form-combobox-search",
                    style: { flex: 2 },
                    attrs: {
                      items: _vm.productList,
                      placeholder: _vm.$t("Product Id or Name"),
                      "prepend-inner-icon": "mdi-magnify",
                      "hide-details": "",
                      dense: "",
                      outlined: ""
                    },
                    on: {
                      "click:prepend-inner": function($event) {
                        return _vm.getProductList(0)
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getProductList(0)
                      }
                    },
                    model: {
                      value: _vm.searchProduct,
                      callback: function($$v) {
                        _vm.searchProduct = $$v
                      },
                      expression: "searchProduct"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "tbl-type05" },
              [
                _c(
                  "v-data-table",
                  {
                    attrs: {
                      "show-select": "",
                      "single-select": "",
                      height: "270",
                      "item-key": "articleId",
                      headers: _vm.headers,
                      items: _vm.productList,
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.articleId",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "110px",
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden",
                                  "white-space": "nowrap"
                                }
                              },
                              [_vm._v(" " + _vm._s(item.articleId) + " ")]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.articleName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "110px",
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden",
                                  "white-space": "nowrap"
                                }
                              },
                              [_vm._v(" " + _vm._s(item.articleName) + " ")]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  },
                  [
                    _c("template", { slot: "no-data" }, [
                      _c("p", [
                        _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                      ])
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "margin-top": "55px",
                "margin-left": "50px"
              }
            },
            [
              _c("div", { staticClass: "pageInfo" }, [
                _vm._v(_vm._s(_vm.pageInfoText))
              ]),
              _c(
                "div",
                { staticClass: "pagination2" },
                [
                  _c("v-pagination", {
                    staticStyle: { display: "block" },
                    attrs: {
                      length: _vm.totalPages * 1 || 1,
                      "total-visible": 10,
                      color: "#2f3b4c"
                    },
                    on: { input: _vm.paging },
                    model: {
                      value: _vm.page,
                      callback: function($$v) {
                        _vm.page = $$v
                      },
                      expression: "page"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "d-flex justify-content-center",
              staticStyle: { "justify-content": "center" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.previewSet }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  style: { width: "112px" },
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelPreviewset }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { overflow: "hidden", width: "1px", height: "1px" }
            },
            [
              _vm.previewDialog
                ? _c(
                    "div",
                    {
                      ref: "refPreview",
                      staticClass: "d-flex",
                      style: {
                        "justify-content": "center",
                        width: _vm.canvas.width + "px",
                        height: _vm.canvas.height + "px"
                      },
                      attrs: { id: "previewCanvas" }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _vm._l(_vm.templateData.bind, function(item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("label", { style: _vm.getStyle(item) }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.column !== "BARCODE"
                                          ? _vm.getArticle(item.column)
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]),
                                item.column === "BARCODE" && false
                                  ? _c("barcode", {
                                      staticClass: "barcode",
                                      attrs: {
                                        options: { displayValue: false },
                                        value: _vm.getArticle(item.column),
                                        tag: "img"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("img", {
                            attrs: {
                              src: _vm.srcURL + _vm.templateItem.template_img
                            }
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticStyle: { width: "1px", height: "1px", overflow: "hidden" }
            },
            [
              _vm.previewDialog
                ? _c("canvas", {
                    attrs: {
                      id: "canvas",
                      width: _vm.canvas.width,
                      height: _vm.canvas.height
                    }
                  })
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c("v-progress-circular", {
            staticStyle: { width: "100%", overflow: "hidden" },
            attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }